@tailwind base;
@tailwind components;
@tailwind utilities;

@import "antd/dist/reset.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "./constants.scss";
@import "./common.scss";
@import "./tabs.scss";
@import "./table.scss";
@import "./createnewCamp.scss";
@import "./fonts.scss";



body {
    font-family: "Montserrat";
}
/* Example CSS for the carousel */

/* Container for the entire carousel */
.carousel-container {
    width: 100%;
    max-width: 800px; /* Adjust the maximum width as needed */
    margin: 0 auto;
    padding: 10px;
}

/* Styles for the carousel */
.custom-carousel {
    border: none;
}

/* Styles for individual carousel items */
.carousel-item {
    display: flex;
    align-items: start;
    justify-content: center;
    min-height: 150px;
    max-height: 150px;
    background-color: #b4d0c1;
    padding: 10px;
    text-align: center;
    overflow: hidden;
}

/* Styles for individual carousel items */
.carousel-item-img {
    display: flex;
    align-items: start;
    justify-content: center;
    min-height: 150px;
    flex-direction: column;
    background-color: #b4d0c1;
    padding: 10px;
    text-align: center;
    overflow: hidden;
}

.carousel-item-text {
    min-height: 150px;
    background-color: #b4d0c1;
    padding: 10px;
    text-align: start;
}

.carousel-item h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-picker-ok {
    // display: none !important;
    button {
        background-color: #056433 !important;
        &:disabled {
            display: none !important;
        }
    }
}
.ant-picker-footer-extra {
    padding: 0;
}
.ant-picker-footer-extra {
    position: absolute;
    top: 0;
    width: 120px;
    right: 0px;
    background-color: white;
    padding: 0px !important;
    margin: 0px !important;

    .timePickerHeader {
        display: grid;
        grid-auto-flow: column;
    }

    .timePickerHeader div {
        text-align: center;
    }
}

.custom-select2{
    .ant-select-selector {
        border: none !important;
        box-shadow: 0px 3px 14px 1px rgb(134 134 134 / 15%) !important;
    }
}

.topCard {
    &_per {
        background-color: #f7f7f7;
        font-size: 12px;
        padding: 0.3rem 0.5rem;
        border-radius: 4px;
        font-family: "Roboto";
        font-weight: 700;
    }
    .text {
        color: #c2c2c2;
    }
    &:hover {
        background-color: $PRIMARY_COLOR;
        .title {
            color: #fff;
        }
        .value {
            color: #fff;
        }
        .text {
            color: #fff;
        }
    }
}

.customCheckBox2 {
    .ant-checkbox-inner {
        // background-color: rgb(8, 83, 8) !important;
        border: 1px solid rgb(8, 83, 8) !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: rgb(8, 83, 8) !important;
        border: 1px solid rgb(8, 83, 8) !important;
    }
}
