@font-face {
     font-family: "Roboto";
     src: url("../assets/fonts/Roboto/Roboto-Regular.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Roboto-Medium";
     src: url("../assets/fonts/Roboto/Roboto-Medium.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Montserrat";
     src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Montserrat-Medium";
     src: url("../assets/fonts/Montserrat/Montserrat-Medium.ttf");
     font-style: normal;
     font-display: swap;
}


@font-face {
     font-family: "Poppins";
     src: url("../assets/fonts/Poppins/Poppins-Medium.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Poppins-Bold";
     src: url("../assets/fonts/Poppins/Poppins-Bold.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Poppins-Light";
     src: url("../assets/fonts/Poppins/Poppins-Light.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Poppins-Italic";
     src: url("../assets/fonts/Poppins/Poppins-Italic.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Truculenta";
     src: url("../assets/fonts/Truculenta/static/Truculenta-Regular.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Truculenta-Light";
     src: url("../assets/fonts/Truculenta/static/Truculenta-Light.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Truculenta-Bold";
     src: url("../assets/fonts/Truculenta/static/Truculenta-Bold.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Diplomata";
     src: url("../assets/fonts/Diplomata_SC/DiplomataSC-Regular.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Caveat";
     src: url("../assets/fonts/Caveat/static/Caveat-Regular.ttf");
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: "Caveat-Bold";
     src: url("../assets/fonts/Caveat/static/Caveat-Bold.ttf");
     font-style: normal;
     font-display: swap;
}

.Roboto {
     font-family: "Roboto";
}

.Roboto-Medium {
     font-family: "Roboto-Medium";
}

.Montserrat {
     font-family: "Montserrat";
}

.Montserrat-Medium {
     font-family: "Montserrat-Medium";
}

.Poppins {
     font-family: "Poppins";
}

.Poppins-Bold {
     font-family: "Poppins-Bold";
}

.Poppins-Light {
     font-family: "Poppins-Light";
}

.Poppins-Italic {
     font-family: "Poppins-Italic";
}

.Truculenta {
     font-family: "Truculenta";
}

.Truculenta-Bold {
     font-family: "Truculenta-Bold";
}

.Truculenta-Light {
     font-family: "Truculenta-Light";
}

.Diplomata {
     font-family: "Diplomata";
}

.Caveat {
     font-family: 'Caveat';
}

.Caveat-Bold {
     font-family: 'Caveat-Bold';
}