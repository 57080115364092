#create-new-camp {
    .ant-radio-wrapper {
        .ant-radio-checked .ant-radio-inner {
            background-color: #fff;
            border-color: #056433;
        }
        .ant-radio-inner::after {
            background-color: #056433;
        }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #056433;
        border-color: #056433;
    }

    .ant-checkbox-group {
        display: flex;
        align-items: baseline;
        flex-direction: row;
        // flex-direction: column;
    }
}