.common {
    &_card {
        background: #ffffff;
        box-shadow: 0px 5px 24px rgba(13, 10, 44, 0.1);
    }
    &_error {
        color: rgb(194, 53, 53);
        font-size: 0.8rem;
    }
    &_normalInput {
        label {
            font-family: "Montserrat";
            font-weight: 500;
        }
        .ant-input {
            font-family: "Montserrat";
            font-size: 14px;
        }
    }
    &_primany_btn {
        background-color: $PRIMARY_COLOR;
        border: 1px solid $PRIMARY_COLOR !important;
        color: #ffffff;
        height: 40px;
        font-family: "Montserrat-Medium";
        &:hover {
            color: #ffffff !important;
            border: 1px solid $PRIMARY_COLOR !important;
        }
    }
    &_light_btn {
        background-color: #fff;
        border: 1px solid #ffffff;
        color: $PRIMARY_COLOR;
        height: 40px;
        font-family: "Montserrat-Medium";
        &:hover {
            color: $PRIMARY_COLOR !important;
            border: 1px solid #fff !important;
        }
    }
}
.close_btn {
    background-color: #d9d9d9;
    color: gray;
}

.searchBox {
    .ant-input-search {
        font-family: "Montserrat";
        font-size: 14px;
        border: none;
    }
    button {
        background-color: #056433;
        &:hover {
            background-color: #056433 !important;
        }
    }
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #056433;
    border-color: #056433;
}
.ant-radio-wrapper {
    .ant-radio-checked .ant-radio-inner {
        background-color: #fff;
        border-color: #056433;
    }
    .ant-radio-inner::after {
        background-color: #056433;
    }
}
.ant-slider .ant-slider-handle::after{
    box-shadow: 0 0 0 2px #056433 !important;
    background-color: #056433;
}
.ant-slider .ant-slider-track {
    background: #056433 !important;
}
.ant-menu-light.ant-menu-inline .ant-menu-item, .ant-menu-light>.ant-menu.ant-menu-inline .ant-menu-item {
    padding-left: 10px !important;
    padding-right: 0px !important;
    background-color: #fff !important;
}
.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline, .ant-menu-light>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #fff !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-top: 10px !important;
}

.ant-checkbox-group {
    display: flex;
    // align-items: baseline;
    flex-direction: column;
}

.ant-carousel .slick-slide {
    text-align: center;
    // height: 80px;
    width: 100% !important;
    // line-height: 160px;
    background: #364d79 !important;
    // overflow: hidden !important;
  }

  .ant-carousel {
    width: 100% !important;
    // height: 160px !important;
  }
  
  .ant-carousel .slick-slide h3 {
    color: #000000e0 !important;
  }

  .ant-dropdown-menu {
    max-height: 400px;
    overflow: auto;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color: #000000e0 !important;
    border-color: #056433 !important;
}
.ant-radio-button-wrapper:hover {
    color: #056433 !important;
    border-color: #056433 !important;
}

.ant-radio-button-wrapper::-webkit-scrollbar {
    display: none;
}

.custom-hover-color:hover {
    color: #056433 !important;
}
.container-ad-text {
    overflow: hidden;
    transition: height 1s ease; /* Smooth transition for the height property */
}
.expanded {
    height: auto;
}

.collapsed {
    height: 140px;
}

.collapsed-img {
    height: 180px;
}